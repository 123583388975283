<template>
  <div class="history">
    <div class="history-main">
      <div class="history-container">
        <h1 class="history-heading">История</h1>
        <span @click="back()" class="back"></span>
          <div class="history-list" v-if="history">
            <template  v-for="(item, idx) in history.data.TransactionList">
              <history-list-item :key="idx" :item="item" />
            </template>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryListItem  from '@/components/HistoryListItem'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      history: state => state.history.history
    })
  },

  methods: {
    back() {
      this.$router.go(-1)
    }
  },

  created() {
    this.$store.dispatch('fetchHistory')
  },

  components: {
    HistoryListItem
  }
}
</script>

<style lang="scss" scoped>
  .history-main {
    background: #1A1C1C;
    position: relative;
    z-index: 1;
    padding-bottom: 100px;
    min-height: calc(100vh - 184px);
    @include display-less(tablet) {
      padding-bottom: 120px;
    }
  }

  .history-container {
    position: relative;
    width: 100%;
    max-width: 640px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; 
    @include display-less(phablet) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include display-less(smart) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .history-heading {
    font: 200 22px/26px $roboto;
    color: #FFFEFA;
    padding-top: 38px;
    margin-bottom: 40px;
    text-align: center;
  }

  .back {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    right: 15px;
    top: 46px;
    background-image: url('../assets/images/icons/close-menu.svg');
    cursor: pointer;
  }
</style>